import axios from 'axios';

const service = axios.create({
    baseURL: '/',
    timeout: 5000,
});

service.interceptors.request.use(config => {
  const requestConfig:any = config;
  requestConfig.start_time = new Date().getTime();

  return requestConfig;
});

service.defaults.xsrfHeaderName = 'x-csrf-token';
service.defaults.xsrfCookieName = 'csrfToken';

service.interceptors.response.use(
    response => response.data,
    error => {
        return Promise.reject(error);
    },
);

export default service;
