import cookies from 'js-cookie';

export const isProduction = process.env.NODE_ENV === 'production';

export const isDev = process.env.NODE_ENV === 'development';

export const isTest = process.env.VUE_APP_ENV === 'test';

export const isInBrowser = typeof window !== 'undefined';

export const isKwai = navigator?.userAgent?.includes('Kwai') || cookies.get('kpn') === 'KWAI';
export const isKwaiMe = cookies.get('kpn') === 'KWAI.ME';
export const isSnack = navigator?.userAgent?.includes('Bulldog') || cookies.get('kpn') === 'KWAI_BULLDOG';

