import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { timeFormatType, timezone, currencyFormatType } from './const';

dayjs.extend(utc);

export function toFixedWithOutRounding(val: number, n: number = 1) {
    const re = new RegExp(`^\\d+(?:\\.\\d{0,${n}})?`);

    return Number(val.toString().match(re));
}

// <10000 正常展示  10000<=x <1000000    >= 100万 m单位  10亿 b
export function formateNum(num: number, fixedNumber?: number) {
    if (num >= 1000000000) {
        return toFixedWithOutRounding(num / 1000000000, fixedNumber) + 'B';
    }
    if (num >= 1000000) {
        return toFixedWithOutRounding(num / 1000000, fixedNumber) + 'M';
    }
    if (num >= 10000) {
        return toFixedWithOutRounding(num / 1000, fixedNumber) + 'K';
    }
    return num;
}

// 版本对比
export function compareVersion(vStr1: string, vStr2: string) {
    const v1 = vStr1.split('.');
    const v2 = vStr2.split('.');
    const len = Math.max(v1.length, v2.length);

    while (v1.length < len) {
        v1.push('0');
    }
    while (v2.length < len) {
        v2.push('0');
    }

    for (let i = 0; i < len; i++) {
        const num1 = parseInt(v1[i], 10);
        const num2 = parseInt(v2[i], 10);

        if (num1 > num2) {
            return 1;
        }

        if (num1 < num2) {
            return -1;
        }
    }
    return 0;
}

export function dateFormat(date: number | Date, formatStr: string = timeFormatType) {
    if (timezone) {
        return dayjs.utc(Number(date) - (timezone * 3600 * 1000)).format(formatStr);
    }
    return dayjs(Number(date)).format(formatStr);
}

// 本地格式化
export const toLocaleString = (value: number) => {
    return value.toLocaleString(currencyFormatType);
};

/**
 *  Cash&Points数值仅能展示六位数
 * Cash数值缩写：
 * 默认保留两位小数，最多展示至R$9999.99
 * 超过四位数，截位，保留一位小数，最多展示至R$99999.9
 * 超过五位数，截位，取整，最多展示至R$999999
 * 后续不做处理
 * @param val
 */
export const fomateCash = (val: number) => {
    if (val < 10 ** 4) {
        return toLocaleString(val);
    }

    if (val < 10 ** 5) {
        return toLocaleString(toFixedWithOutRounding(val, 1));
    }

    if (val < 10 ** 6) {
        return toLocaleString(toFixedWithOutRounding(val, 0));
    }

    if (val < 10 ** 9) {
        return toLocaleString(toFixedWithOutRounding(val / 10 ** 3, 0)) + 'K';
    }

    return toLocaleString(999999) + 'K';
};

/**
 * 格式化金币展示
 * @param val
 */
export const fomatePoints = (val: number) => {
    if (val < 10 ** 6) {
        return toLocaleString(val);
    }

    if (val < 10 ** 10) {
        return toLocaleString(toFixedWithOutRounding(val / 10 ** 6, 2)) + 'M';
    }

    if (val < 10 ** 11) {
        return toLocaleString(toFixedWithOutRounding(val / 10 ** 6, 1)) + 'M';
    }

    if (val < 10 ** 12) {
        return toLocaleString(toFixedWithOutRounding(val / 10 ** 6, 0)) + 'M';
    }

    return toLocaleString(999999) + 'M';
};

export const serialize = function (obj: Record<string, any>) {
    const str = [];
    for (const p in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
        }
    }
    return str.join('&');
};

export const getQuery = function () {
    const url = location.search; // 获取url中"?"符后的字串
    const theRequest = Object.create(null);
    if (url.indexOf('?') !== -1) {
        const str = url.substr(1);
        const strs = str.split('&');
        for (let i = 0; i < strs.length; i++) {
            theRequest[strs[i].split('=')[0]] = unescape(strs[i].split('=')[1]);
        }
    }
    return theRequest;
};

export const lerp = (x: number, y: number, a: number) => x * (1 - a) + y * a;
export const clamp = (a: number, min = 0, max = 1) => Math.min(max, Math.max(min, a));
export const invlerp = (x: number, y: number, a: number) => clamp((a - x) / (y - x));
export const range = (x1: number, y1: number, x2: number, y2: number, a: number) => lerp(x2, y2, invlerp(x1, y1, a));

/**
 * 链接追加参数
 * @param link 链接
 * @param params 参数
 * @returns string
 */
export const addParamsToLink = (link: string, params: Record<string, string>) => {
    if (!link) {
        return link;
    }
    const paramStr = Object.entries(params).map(([key, value]) => `${key}=${value}`).join('&');
    const linkSign = link.includes('?') ? '&' : '?';

    return link + linkSign + paramStr;
};
// 灰度
// model:模
// min-max范围
export const grayscale = (rule: {
    model: number,
    min: number,
    max: number,
    whitelist?: number[],
    blacklist?: number[]
}, value: number) => {
    try {
        const { model, min, max, whitelist, blacklist } = rule;
        if (blacklist && blacklist.includes(value)) { return false; } // 黑名单
        if (whitelist && whitelist.includes(value)) { return true; } // 白名单
        const temp = value % model;
        if (temp >= min && temp <= max) {
            return true;
        }
        return false;
    } catch (error) {
        return false;
    }
};