






import { defineComponent } from '@vue/composition-api';

export default defineComponent({
    name: 'img-component',
    props: {
        url: String,
    },
    setup() {

        return {
            publicPath: '../../../../public/image',
        };
    },
});
