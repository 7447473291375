/* eslint-disable no-nested-ternary */
import { Weblog, PVOptions, ShowOptions, TaskOptions, CustomOptions } from '@ks/weblogger';
import cookies from 'js-cookie';
import { isProduction, isSnack, isKwaiMe } from '@/common/env';

type NetworkType = 'unknown' | 'none' | 'wifi' | '4g' | '3g' | '2g' | undefined;


const growthLogConfig = {
    env: isProduction? 'oversea': 'test',
    // env:'production',
    proto: 'v3',
    autoPV: false,
    fps: false,
    logger: !isProduction,
    radar: {
        // 雷达平台上的唯一标识
        projectId: 'd4ccd0414c',
        // 上报采样率，1 为全部上报
        sampling: 1,
    },
};

// kwai_growth_h5公参配置
const growthBaseOptions = {
    product_name: 'kwai_growth_h5',
    package_name: 'kwai_growth_h5',
};

// kwai_growth_h5打点
export const webGrowthLogger = new Weblog(growthLogConfig as any, growthBaseOptions);

// 日志类型
enum LogType {
    // 曝光
    show = 'SHOW',
    // 点击
    click = 'CLICK',
    // PV
    pv = 'PV',
    // 雷达事件
    custom = 'CUSTOM',
}

export interface LogData {
    type: keyof typeof LogType;
    name: string;
    value?: Record<string, any>;
    eventId?: string;
}

/**
 * 生成上报参数
 * @param data 上报数据
 * @returns PVOptions | ShowOptions | TaskOptions | CustomOptions
 */
const generateLogParams = (data: LogData): PVOptions | ShowOptions | TaskOptions | CustomOptions => {
    // PV曝光其他参数暂时不支持
    if (data.type === 'pv') {
        return {
            type: 'enter',
            page: data.name,
            params: data.value,
            eventId: data.eventId,
        } as PVOptions;
    }

    if (data.type === 'custom') {
        return {
            key: data.name,
            value: data.value,
            eventId: data.eventId,
        } as CustomOptions;
    }

    // click 和 show 事件
    return {
        action: data.name,
        params: data.value,
        eventId: data.eventId,
    } as ShowOptions | TaskOptions;
};

/**
 * 数据上报
 * @param data 上报数据
 * @param immediate 是否立即上报
 */
const logCollect_growth = (data: LogData, immediate?: boolean) => {
    const { type } = data;

    // 不存在类型则不上报
    if (!type) {
        return;
}

    const logParams = generateLogParams(data);
    const logType = LogType[type];

    if (immediate) {
        webGrowthLogger.sendImmediately(logType, logParams);
    } else {
        webGrowthLogger.collect(logType, logParams);
    }
};

/**
 * 收集上报埋点
 * kwai_growth_h5相关
 * @param data
 */
export const sendLog_growth = (data: LogData) => {
    logCollect_growth(data);
};

/**
* 立即上报埋点
* @param data
*/
export const immediateSendLog_growth = (data: LogData) => {
    logCollect_growth(data, true);
};

interface RadarLogData {
    // 事件名（必填）
    name: string;
    // 事件类型（可选）
    event_type?: string;
    // 业务分类（可选）
    category?: string;
    // 事件相关源文件（可选）
    src?: string;
    // 事件结果（可选）
    result_type?: string;
    // 消息（可选）
    message?: string;
    // 额外信息（建议使用json string - 可选）
    extra_info?: string;
    // 时长，可以计算分位数，不涉及可以不传（可选）
    duration?: number;
}
