import {getQuery} from '@/common/util';

// 解决 accept-language 有些user-agent返回相同语言的language-code不一致问题
const languageMapping: Langs = {
    'zh-HK': 'zh-TW', // zh-HK与zh-TW相同处理
    'ko-kr': 'ko', // ko-kr与ko相同处理
    'tr-TR': 'tr',
    'it-CH': 'it',
    'it-IT': 'it',
    'vi-vn': 'vi', // vi-vn与vi相同处理
    'pt': 'pt-BR',
    'pt-br': 'pt-BR',
    'zh-tw': 'zh-TW',
    'fil-PH': 'en-PH',
};
  
const baseSupportLanguages = [
    'en',     // 英语
    'th',     // 泰语
    'id',     // 印尼语
    'ur',     // 乌尔都语
    'es',     // 西语
    'pt-BR',  // 葡语
    'ar',     // 阿语
    'ru',     // 俄语
    'tr',     // 土耳其语
    'zh',
];
  
const BASE_LANG_CODE = 'en';
  
export const getLangCode = () => {
    let languageCode: string = BASE_LANG_CODE;
    // 语言从本地获取
    const nLanguage = navigator.language || BASE_LANG_CODE;
    const language = languageMapping[nLanguage] || nLanguage;
    
    if (baseSupportLanguages.includes(language)) {
        languageCode = language;
    } else if (baseSupportLanguages.includes(language.split('-')[0])) {
        languageCode = language.split('-')[0];
    }
    const langArray = languageCode.split('-');
    if (langArray.length > 1) {
        languageCode = `${langArray[0]}-${langArray[1].toUpperCase()}`;
    }
    return languageCode;
};

export const isRTL = () => {
    const lang = getQuery()?.lang || getLangCode();
    const RTL_LANGS = ['ar', 'ur', 'pa', 'ps', 'sd'];
    return RTL_LANGS.includes(lang);
};
  
export const supportRtl = (): void => {
    const languageCode = getQuery()?.lang || getLangCode();
    // 适配rtl
    if (['ar', 'ur', 'pa', 'ps', 'sd'].includes(languageCode)) {
        const className = document.body.className;
        document.body.setAttribute('dir', 'rtl');
        document.body.className = `${className} rtl`;
    }
    console.log(`language: ${languageCode}`);
};

interface Langs {
[key: string]: string;
}
  