import cookies from 'js-cookie';

export const onelink = 'https://go.onelink.me/iGO2/5bbbb3b4';
// 裂变一期
export const fissionOnelink = 'https://go.onelink.me/iGO2/referral';
// 分享吊起控制面板 通用params
export const shareParams = {
    type: 'type',
    caption: '',
    desc: '',
    siteName: '',
    imgUrl: 'https://ak-static.kwai.net/kos/nlav10586/encourage/invite-share-1200_600.afe757d0c9e2b1fa.png',
    siteUrl: '',
    platform: [
        'whatsapp',
        'facebook_kwai',
        'messenger',
        'messenger_lite',
        'twitter',
        'twitter_lite',
        'telegram',
        'pinterest',
        'line',
        'sms',
        'telegram',
        'share_copylink',
        'copy',
        'more',
    ],
    shareSource: 'shareSource',
    activity_name: '',
    activity_id: '',
};

export const mProKwaiOrigin = process.env.HOST || window.location.origin;

export const userId = cookies.get('userId') || cookies.get('ud') || '';

export const did = cookies.get('_did') || cookies.get('did') || '';


// 国家列表
export enum COUNTRY_LIST {
    BRA = 'br',
    COL = 'col',
    MEX = 'mex',
    ARG = 'arg',
    PER = 'per',
    CHL = 'chl',
    ECU = 'ecu',
}

// 获取国家
const getCountry = (): COUNTRY_LIST => {
    const webCountry = cookies.get('web_country');

    if (webCountry) {
        return webCountry as COUNTRY_LIST;
    }

    // 客户端注入的Cookie
    const nativeCountry = (cookies.get('countryInfo') || '').toLowerCase();

    if (nativeCountry === 'bra') {
        return COUNTRY_LIST.BRA;
    }

    return nativeCountry as COUNTRY_LIST;
};

// 当前国家
export const webCountry = getCountry();

// 支持的桶列表
export enum BUCKET_LIST {
    BR = 'br',
    SPA = 'spa',
    Th = 'th',
    EGY = 'egy',
    PAK = 'pak',
    IN = 'in',
}

// 当前桶
export const webBucket = cookies.get('web_bucket') as BUCKET_LIST;
export const bucket = cookies.get('bucket') as BUCKET_LIST;

// 各个国家时间展示格式列表
const timeFormatTypeMap: Record<COUNTRY_LIST, string> = {
    [COUNTRY_LIST.BRA]: 'YYYY-MM-DD HH:mm',
    [COUNTRY_LIST.COL]: 'DD-MM-YYYY HH:mm',
    [COUNTRY_LIST.MEX]: 'DD-MM-YYYY HH:mm',
    [COUNTRY_LIST.ARG]: 'DD-MM-YYYY HH:mm',
    [COUNTRY_LIST.PER]: 'DD-MM-YYYY HH:mm',
    [COUNTRY_LIST.CHL]: 'DD-MM-YYYY HH:mm',
    [COUNTRY_LIST.ECU]: 'DD-MM-YYYY HH:mm',
};

// 时间展示格式
export const timeFormatType = timeFormatTypeMap[webCountry];

// 各个国家时区列表
const timezoneMap: Record<COUNTRY_LIST, number> = {
    [COUNTRY_LIST.BRA]: 3,
    [COUNTRY_LIST.COL]: 5,
    [COUNTRY_LIST.MEX]: 6,
    [COUNTRY_LIST.ARG]: 3,
    [COUNTRY_LIST.PER]: 5,
    [COUNTRY_LIST.CHL]: 4,
    [COUNTRY_LIST.ECU]: 5,
};

// 时区
export const timezone = timezoneMap[webCountry];

// 货币格式化列表
const currencyFormatTypeMap: Record<COUNTRY_LIST, string> = {
    [COUNTRY_LIST.BRA]: 'pt-br',
    [COUNTRY_LIST.COL]: 'es-co',
    [COUNTRY_LIST.MEX]: 'es-mx',
    [COUNTRY_LIST.ARG]: 'es-ar',
    [COUNTRY_LIST.PER]: 'es-pe',
    [COUNTRY_LIST.CHL]: 'es-cl',
    [COUNTRY_LIST.ECU]: 'es-ec',
};

export const currencyFormatType = currencyFormatTypeMap[webCountry];

export const country = getCountry();

const countryUnitMap: Record<string, string> = {
    col: 'COP',
    mex: 'MXN',
    arg: 'ARS',
    per: 'PEN',
    chl: 'CLP',
    ecu: 'USD',
};

export const countryUnit = countryUnitMap[country];

const areaCodeMap: Record<string, string> = {
    br: '+55',
    col: '+57',
    mex: '+52',
    arg: '+54',
    per: '+51',
    chl: '+56',
    ecu: '+593',
};

export const areaCode = areaCodeMap[country];

const accountKeyMap: Record<string, string> = {
    br: 'KWAI_CASH',
    col: 'KWAI_PRO_CASH_COL',
    mex: 'KWAI_PRO_CASH_MEX',
    arg: 'KWAI_PRO_CASH_ARG',
    per: 'KWAI_PRO_CASH_PER',
    chl: 'KWAI_PRO_CASH_CHL',
    ecu: 'KWAI_PRO_CASH_ECU',
};

export const countryAccountKey = accountKeyMap[country];

const giftCardKeyMap: Record<string, string> = {
    br: 'KWAI_PRO_DTONE_KEY',
    col: 'KWAI_PRO_DTONE_KEY_COL',
    mex: 'KWAI_PRO_DTONE_KEY_MEX',
    arg: 'KWAI_PRO_DTONE_KEY_ARG',
    per: 'KWAI_PRO_DTONE_KEY_PER',
    chl: 'KWAI_PRO_DTONE_KEY_CHL',
    ecu: 'KWAI_PRO_DTONE_KEY_ECU',
};

export const giftCardKey = giftCardKeyMap[country];

function decode(str: string) {
    try {
        return decodeURIComponent(str);
    } catch {
        // noop
    }
    return str;
}

export function getRequest() {
    let url = decode(window.location.search);
    const theRequest: Record<string, string> = {};
    if (url.indexOf('?') !== -1) {
        url = url.substr(1);
    }
    if (url) {
        const strs = url.split('&');
        for (let i = 0; i < strs.length; i++) {
            const srtArry = strs[i].split('=');
            const y = srtArry.shift() as string;
            theRequest[y] = decode(srtArry.join('='));
        }
    }
    return theRequest;
}

export function getQuery (param: string){
    if (URLSearchParams){
        const urlSearchParams = new URLSearchParams(window.location.search);
        return urlSearchParams.get(param);
    }

    const urlObject = getRequest();
    return urlObject[param];
};